import React, { useEffect, useState } from "react";
import {
  FaIndustry,
  FaRegStar,
  FaStar,
  FaTags,
  FaThList,
} from "react-icons/fa";
import { MdLocationOn, MdLocationPin } from "react-icons/md";
import Navbar from "../Navbar";
import Footer from "../Footer";
import FooterBanner from "../FooterBanner";
import Filter from "../Filter";
import {
  AiFillHeart,
  AiOutlineFullscreen,
  AiOutlineHeart,
} from "react-icons/ai";
import { Link, json, useParams } from "react-router-dom";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import { BiExitFullscreen } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { creatFavouriteAds, getAdsList } from "../../slice/ListSlice";
import {
  GrFormNextLink,
  GrFormPreviousLink,
  GrNext,
  GrPrevious,
} from "react-icons/gr";

const truncateDescription = (text, maxLength) => {
  if (text.length <= maxLength) {
    return text;
  }
  return text.substring(0, maxLength) + "...";
};

const Business = () => {
  const dispatch = useDispatch();
  const { slug, skip, limit } = useParams();
  const [liked, setLiked] = useState({});
  const businessAdsData = useSelector((store) => store.ads.catAdsList);
  const businessAds = businessAdsData?.data || {};

  const [singleColumnView, setSingleColumnView] = useState(true);
  const [multipleColumnView, setMultipleColumnView] = useState(false);
  const itemsPerPage = 10;
  const totalDataCount = businessAds?.total || 0;
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const maxLength = 100;
  // console.log("->>>>", businessAds);

  useEffect(() => {
    dispatch(
      getAdsList({
        category: slug,
        skip: (currentPage - 1) * itemsPerPage,
        limit: itemsPerPage,
      })
    );
  }, [slug, currentPage]);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    const totalPages = Math.ceil(totalDataCount / itemsPerPage);
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePageChange = (page) => {
    if (page >= 1 && page <= Math.ceil(totalDataCount / itemsPerPage)) {
      setCurrentPage(page);
    }
  };

  const handleSingleColumnView = () => {
    setSingleColumnView(true);
    setMultipleColumnView(false);
  };

  const handleMultipleColumnView = () => {
    setSingleColumnView(false);
    setMultipleColumnView(true);
  };

  const addToFavourite = (customer_id, listing_id, index) => {
    if (liked[index]) {
      dispatch(
        creatFavouriteAds({
          data: { customer_id, listing_id, is_favorite: false },
        })
      );
    } else {
      dispatch(
        creatFavouriteAds({
          data: { customer_id, listing_id, is_favorite: true },
        })
      );
    }

    setLiked((prevLiked) => ({
      ...prevLiked,
      [index]: !prevLiked[index],
    }));
  };

  return (
    <div>
      <Navbar />
      <FooterBanner title={slug} />

      <div className=" w-full pt-5 flex justify-center pb-10">
        <div className=" w-11/12 flex flex-col-reverse lg:flex lg:flex-row gap-5">
          <Filter />
          {businessAds.items?.length > 0 ? (
            <div className="w-full flex-col gap-5 sm:w-full md:w-full lg:w-2/3 xl:2/3 flex">
              <div className="flex justify-between gap-5">
                <div className="flex justify-between gap-4 pt-6 lg:py-0">
                  <button
                    className="flex justify-center items-center text-md font-semibold border-2 border-[#234777] hover:bg-[#234777] hover:text-white py-1 px-4"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    <div>
                      <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="text-xl " height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path className="hover:text-white" fill="currentColor" stroke="currentColor" stroke-width="2" d="M6,12.4 L18,12.4 M12.6,7 L18,12.4 L12.6,17.8" transform="matrix(-1 0 0 1 24 0)"></path></svg>
                    </div>
                    PREVIOUS
                  </button>
                  <button
                    className="flex justify-center items-center text-md font-semibold border-2 border-[#234777] hover:bg-[#234777] hover:text-white py-1 px-4"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage * itemsPerPage >= totalDataCount}
                  >
                    NEXT
                    <div>
                      <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="text-xl" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path className="hover:text-white" fill="currentColor" stroke="currentColor" stroke-width="2" d="M6,12.4 L18,12.4 M12.6,7 L18,12.4 L12.6,17.8"></path></svg>
                    </div>
                  </button>
                </div>
                <div className="flex justify-between gap-4 pt-6 lg:py-0">
                  <button>
                    <FaThList
                      onClick={handleSingleColumnView}
                      className="w-6 h-6 "
                    />
                  </button>
                  <button onClick={handleMultipleColumnView}>
                    <BsFillGrid3X3GapFill className="w-6 h-6 " />
                  </button>
                </div>
              </div>
              {singleColumnView && (
                <div className="flex flex-col gap-5">
                  {businessAds.items?.map((items, index) => (
                    <div
                      key={index}
                      className="group bg-white border rounded-lg p-4 flex flex-col md:flex md:flex-row md:gap-5 lg:flex lg:gap-5 hover:shadow-lg duration-500 h-full "
                    >
                      {/* Your card rendering code here */}
                      <Link
                        to={`/ads-detail/${items.slug}`}
                        className="w-full md:w-1/4 "
                      >
                        <img
                          className="object-contain transition ease-in-out duration-700 group-hover:scale-110 w-full h-[15vh] lg:h-[20vh] "
                          src={items?.images[0]?.image_path}
                          alt={items.title}
                          onError={(e) => {
                            e.target.src = "/img/no-image.png";
                          }}
                        />
                      </Link>
                      <Link className=" flex flex-col md:flex md:flex-row md:gap-5 lg:flex lg:gap-5 w-full "
                        to={`/ads-detail/${items.slug}`}>
                      <div className="w-full  flex flex-col justify-around">
                        <div className="text-teal-400 font-semibold flex gap-2 items-center">
                          <span className="text-gray-500">
                            <FaIndustry />
                          </span>
                          <span>{items.category.slug}</span>
                        </div>
                        <div>
                          <hr></hr>
                        </div>
                        <div className="text-lg font-bold text-[#234777]">
                          {items.head}
                        </div>
                        <Link
                          to={`/ads-detail/${items.slug}`}
                          className="text-lg font-medium text-slate-600 group-hover:text-yellow-500"
                        >
                          {truncateDescription(items.title, maxLength)}
                        </Link>
                        <div className="flex items-center font-light">
                          <div className="text-gray-500">
                            <MdLocationPin />
                          </div>
                          <div className="text-[#999999] text-lg font-normal">
                            {items.location.city}
                          </div>
                        </div>
                        <div>
                          <hr></hr>
                        </div>
                        <div className=" flex justify-between">
                          <div className="flex items-center ">
                            <div className="text-xl font-bold">
                              {items.currency.symbol} {items.price}
                            </div>
                          </div>

                          <div className="flex justify-between ">
                            <div className=" border-l-2 border-slate-200">
                              <Link to={`/ads-detail/${items.slug}`}>
                                <button className="p-1 hover:scale-110 duration-300">
                                  <AiOutlineFullscreen className="w-5 h-5 hover:text-blue-950" />
                                </button>
                              </Link>
                            </div>

                            <div className=" border-l-2 border-slate-200">
                              <button
                                className="p-1 hover:scale-110 duration-300"
                                onClick={() =>
                                  addToFavourite(
                                    items.customer_id,
                                    items.listing_id,
                                    index
                                  )
                                }
                              >
                                {liked[index] ? (
                                  <FaStar className="text-yellow-400 w-5 h-5" />
                                ) : (
                                  <FaRegStar className="w-5 h-5" />
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      </Link>
                    </div>
                  ))}
                </div>
              )}
              {multipleColumnView && (
                <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 sm:justify-center md:grid-cols-3 lg:grid-cols-3 lg:justify-between ">
                  {businessAds.items?.map((items, index) => (
                    <div
                      className="w-full  overflow-hidden border border-slate-200 shadow-lg rounded-lg"
                      key={index}
                    >
                      <div className="w-full ">
                        <img
                          className=" transition ease-in-out duration-700 group-hover:scale-110 w-full transform hover:scale-105 object-fill max-h-56"
                          src={items?.images[0]?.image_path}
                          alt={items.title}
                          onError={(e) => {
                            e.target.src = "/img/no-image.png";
                          }}
                        />
                      </div>
                      <div className="p-4">
                        <div className="flex items-center gap-1 text-[#000] pb-2">
                          <div>
                            <FaIndustry />
                          </div>
                          <div>{items.category.name}</div>
                        </div>
                        <div>
                          <hr className="border-1 text-[#999999]" />
                        </div>
                        <div className="text-lg text-[#232D3B] py-1">
                          <div className="font-bold text-[#234777]">
                            {items.head}
                          </div>
                        </div>
                        <div className="flex items-start gap-1 pb-2 text-[#000]">
                          <div className="pt-1">
                            <MdLocationOn />
                          </div>
                          <div className="">{items.location.city}</div>
                        </div>
                        <div>
                          <hr className="border-1 text-[#999999]" />
                        </div>
                        <div className="flex justify-between align-middle font-bold text-[#232D3B] text-lg py-2">
                          <div className="w-7/12">
                            {" "}
                            {items.currency.symbol} {items.price}
                          </div>
                          <div className=" w-3/12 flex justify-end">
                            <div className=" border-l-2 border-slate-200">
                              <Link
                                to={`/ads-detail/${items.slug}`}
                                key={index}
                              >
                                <button className="p-1 hover:scale-110 duration-300">
                                  <BiExitFullscreen className="w-5 h-5" />
                                </button>
                              </Link>
                            </div>
                            <div className=" border-l-2 border-slate-200">
                              <button
                                className="p-1 hover:scale-110 duration-300"
                                onClick={() =>
                                  addToFavourite(
                                    items.customer_id,
                                    items.listing_id,
                                    index
                                  )
                                }
                              >
                                {liked[index] ? (
                                  <FaStar className="text-yellow-400 w-5 h-5" />
                                ) : (
                                  <FaRegStar className="w-5 h-5" />
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              <div className="flex justify-between">
                <div className="flex justify-between gap-4 pt-6 lg:py-0">
                  <button
                    className="flex justify-center items-center text-sm font-semibold border-2 rounded-md border-[#234777] hover:bg-[#234777] hover:text-white py-1 px-4"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    <div>
                      <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="text-xl " height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path className="hover:text-white" fill="currentColor" stroke="currentColor" stroke-width="2" d="M6,12.4 L18,12.4 M12.6,7 L18,12.4 L12.6,17.8" transform="matrix(-1 0 0 1 24 0)"></path></svg>
                    </div>
                    PREVIOUS
                  </button>
                  <button
                    className="flex justify-center items-center text-sm font-semibold border-2 rounded-md border-[#234777] hover:bg-[#234777] hover:text-white py-1 px-4"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage * itemsPerPage >= totalDataCount}
                  >
                    NEXT
                    <div>
                      <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="text-xl" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path className="hover:text-white" fill="currentColor" stroke="currentColor" stroke-width="2" d="M6,12.4 L18,12.4 M12.6,7 L18,12.4 L12.6,17.8"></path></svg>
                    </div>
                  </button>
                </div>

                <div className="mt-2 text-lg">
                  Showing{" "}
                  <strong>
                    {startIndex + 1}-{Math.min(endIndex, totalDataCount)}{" "}
                  </strong>{" "}
                  of <strong>{totalDataCount}</strong> results.
                </div>
              </div>
            </div>
          ) : (
            <div className="flex  flex-col justify-center  lg:min-h-[24vh] md:min-h-[30vh] xl:min-h-[27vh] 2xl:min-h-[34.8vh">
              <div className=" text-[#01c6da] border-2 border-[#234777] rounded-md py-10 text-center">
                {" "}
                <span className="text-2xl italic">No results found</span>
              </div>
            </div>
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Business;
