import React, { useEffect, useState } from "react";
import { GrFormPreviousLink, GrFormNextLink } from "react-icons/gr";
import { BiExitFullscreen } from "react-icons/bi";
import { MdLocationOn } from "react-icons/md";
import { FaTags } from "react-icons/fa";
import { Link } from "react-router-dom";
import { getNewAds } from "../slice/ListSlice";
import { useDispatch, useSelector } from "react-redux";
import NewAdsSlide from "../Component/NewAdsSlide";
import utils from "../helper/utils";
import ListingFormPopup from "./ListingFormPopup";
import ModalCategoryPostAd from "./ModalCategoryPostAd";
import { PiPencil } from "react-icons/pi";

const RenderImage = ({ url, items }) => {
  const [isValid, setIsValid] = useState(null);

  useEffect(() => {
    const checkImage = async () => {
      const valid = await utils.isValidImageURL(url);
      setIsValid(valid);
    };

    checkImage();
  }, [url]);
  if (!url) {
    return (
      <Link to={`/ads-detail/${items.slug}`}>
        <div className="h-48">
          <img
            src="/img/no-image.png"
            alt={items.title}
            className="transform duration-500 hover:scale-105 h-full w-full object-cover"
          />
        </div>
      </Link>
    );
  }
  return (
    <>
      {isValid && (
        <Link to={`/ads-detail/${items.slug}`}>
          <div className="h-48">
            <img
              src={items.images[0]?.image_path}
              alt={items.title}
              onError={(e) => {
                e.target.src = "/img/no-image-classified.jpg";
              }}
              className="transform duration-500 hover:scale-105 h-full w-full object-cover"
            />
          </div>
        </Link>
      )}
    </>
  );
};

const MyClassifiedAds = () => {
  const dispatch = useDispatch();
  const newAdsData = useSelector((store) => store.ads.newAds);
  const newAdsList = newAdsData?.data || [];
  const itemsPerPage = 20;
  const totalDataCount = newAdsList?.total || 0;
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const maxLength = 100;

  const [isOpen, setIsOpen] = useState(false);
  const [selectedData, setSelectedData] = useState({});

  const truncateString = (str, maxLength) => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    }
    return str;
  };

  const handlePageChange = (page) => {
    if (page >= 1 && page <= Math.ceil(totalDataCount / itemsPerPage)) {
      setCurrentPage(page);
    }
  };

  const init = () => {
    dispatch(
      getNewAds({
        skip: (currentPage - 1) * itemsPerPage,
        limit: itemsPerPage,
      })
    );
  };
  useEffect(() => {
    init();
  }, [currentPage]);

  const onRefresh = () => {
    setCurrentPage(1);
    init();
  };
  return (
    <div className=" relative pt-32 pb-10 lg:pt-20 w-11/12 mx-auto bg-transparent">
      <div className=" flex flex-col justify-between py-5">
        <div className="p-10 bg-rose-100 rounded-2xl flex justify-between items-center mb-5">
          <div className="flex-1">
            <h1 className="text-blue-500 font-bold text-4xl mb-5">
              <span className="text-[#234777]">My Classifieds</span>
              <span className="text-[#01C6DA]"> Adverts</span>
            </h1>
            <p>
              Classified adverts are short, targeted listings typically used for
              promoting goods, services, or job opportunities, providing a
              simple and cost-effective way for individuals or businesses to
              reach potential buyers or clients.
            </p>
            <div className="flex mt-8">
              <ModalCategoryPostAd />
            </div>
          </div>
          <div className="md:flex-1 justify-end hidden md:flex">
            <img
              className="transform duration-500 hover:scale-105 w-[400px] object-cover"
              src="/img/my-ads.png"
              alt="header"
            />
          </div>
        </div>
        <div className="flex justify-between gap-4 pt-6 lg:py-0">
          <button
            className="flex justify-center items-center text-sm font-semibold border-2 rounded-md border-[#234777] hover:bg-[#234777] hover:text-white py-1 px-4"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
             <div>
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="text-xl " height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path className="hover:text-white" fill="currentColor" stroke="currentColor" stroke-width="2" d="M6,12.4 L18,12.4 M12.6,7 L18,12.4 L12.6,17.8" transform="matrix(-1 0 0 1 24 0)"></path></svg>
          </div>
            PREVIOUS
          </button>
          <button
            className="flex justify-center items-center text-sm font-semibold border-2 rounded-md border-[#234777] hover:bg-[#234777] hover:text-white py-1 px-4"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage * itemsPerPage >= totalDataCount}
          >
            NEXT
              <div>
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="text-xl" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path className="hover:text-white" fill="currentColor" stroke="currentColor" stroke-width="2" d="M6,12.4 L18,12.4 M12.6,7 L18,12.4 L12.6,17.8"></path></svg>
          </div>
          </button>
        </div>
      </div>
      {/* <div className="bg-yellow-400 flex flex-wrap  mt-10 w-full "> */}
      {newAdsList.items?.length > 0 ? (
        <div className=" grid grid-cols-1 gap-4 sm:grid-cols-2 sm:justify-center md:grid-cols-2 lg:grid-cols-5 lg:justify-between ">
          {newAdsList.items?.map((items, index) => (
            <div
              key={index}
              className="group w-full overflow-hidden  border border-slate-200 shadow-lg rounded-lg"
            >
              <RenderImage url={items.images[0]?.image_path} items={items} />
              <div className="p-4">
                <div className="flex items-center gap-1 text-[#999999] pb-2">
                  <div>
                    <FaTags />
                  </div>
                  <div>{items.category.name}</div>
                </div>
                <div>
                  <hr className="border-1 text-[#999999]" />
                </div>
                <div className="text-lg text-[#232D3B] py-1">
                  <Link
                    to={`/ads-detail/${items.slug}`}
                    className="font-bold group-hover:text-yellow-500"
                  >
                    {truncateString(items.title, 30)}
                  </Link>
                </div>
                <div className="flex items-start gap-1 pb-2 text-[#999999]">
                  <div className="pt-1">
                    <MdLocationOn />
                  </div>
                  <div className="">{items.location.city}</div>
                </div>
                <div>
                  <hr className="border-1 text-[#999999]" />
                </div>
                <div className="flex justify-end align-middle font-bold text-[#232D3B] text-lg py-2">
                  {/* <div className="font-bold text-[#232D3B]  w-7/12">
                    {items.price}
                  </div> */}
                  <div className=" w-3/12 flex justify-end">
                    <div className=" border-l-2 border-slate-200">
                      <Link to={`/ads-detail/${items.slug}`} key={index}>
                        <button className="p-1 hover:scale-110 duration-300">
                          <BiExitFullscreen className="w-5 h-5" />
                        </button>
                      </Link>
                    </div>
                    <div className=" border-l-2 border-slate-200">
                      <button
                        className="p-1 hover:scale-110 duration-300"
                        onClick={() => {
                          setSelectedData(items);
                          setIsOpen(true);
                        }}
                      >
                        <PiPencil className="w-5 h-5" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p>no new ads Available</p>
      )}
      <div className="flex justify-between py-6">
        <div className="mt-2 text-lg">
          Showing{" "}
          <strong>
            {startIndex + 1}-{Math.min(endIndex, totalDataCount)}{" "}
          </strong>{" "}
          of <strong>{totalDataCount}</strong> results.
        </div>
        <div className="flex justify-between gap-4 pt-6 lg:py-0">
          <button
            className="flex justify-center items-center text-sm font-semibold border-2 rounded-md border-[#234777] hover:bg-[#234777] hover:text-white py-1 px-4"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
             <div>
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="text-xl " height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path className="hover:text-white" fill="currentColor" stroke="currentColor" stroke-width="2" d="M6,12.4 L18,12.4 M12.6,7 L18,12.4 L12.6,17.8" transform="matrix(-1 0 0 1 24 0)"></path></svg>
          </div>
            PREVIOUS
          </button>
          <button
            className="flex justify-center items-center text-sm font-semibold border-2 rounded-md border-[#234777] hover:bg-[#234777] hover:text-white py-1 px-4"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage * itemsPerPage >= totalDataCount}
          >
            NEXT
              <div>
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="text-xl" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path className="hover:text-white" fill="currentColor" stroke="currentColor" stroke-width="2" d="M6,12.4 L18,12.4 M12.6,7 L18,12.4 L12.6,17.8"></path></svg>
          </div>
          </button>
        </div>
      </div>
      <NewAdsSlide />
      <ListingFormPopup
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        data={selectedData}
        onRefresh={() => {
          onRefresh();
        }}
      />
    </div>
  );
};

export default MyClassifiedAds;
