/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../Navbar";
import {
  FaStar,
  FaStore,
  FaTelegram,
  FaTwitter,
  FaWhatsapp,
} from "react-icons/fa";
import { AiFillHeart, AiFillMessage } from "react-icons/ai";
import { MdLocationOn, MdOutlineReportProblem } from "react-icons/md";
import { ImCross } from "react-icons/im";
import Footer from "../Footer";
import { useDispatch, useSelector } from "react-redux";
import { detailsAdsList, creatFavouriteAds } from "../../slice/ListSlice";
import TopAffiliateOnAdsDetail from "../TopAffiliateOnAdsDetail";
import Env from "../../useEnv";
import { BsTwitter } from "react-icons/bs";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import RelatedSlider from "./RelatedAds";
import { Link } from "react-router-dom";
import EbayAds from "../EbayAds";
import PostNewAds from "../PostNewAds";
import BottomAds from "../BottomAds";

const googleMapsApiKey = Env.GoogleApiKey;

function FeaturedAdsDetail() {
  const dispatch = useDispatch();
  const { slug } = useParams();

  const adsDetails = useSelector((store) => store.ads.detailsAds);
  const adsDetailData = adsDetails?.data || [];

  const [shareOverlay, setShareOverlay] = useState(false);

  useEffect(() => {
    if (slug) {
      dispatch(detailsAdsList({ slug }));
    }
  }, [slug]);

  const handleShareButton = () => {
    setShareOverlay(true);
  };
  const closeShareOverlay = () => {
    setShareOverlay(false);
  };

  const addToFavourite = (customer_id, listing_id) => {
    dispatch(
      creatFavouriteAds({
        data: { customer_id, listing_id, is_favorite: true },
      })
    );
  };
  const buildURL = (slug) => {
    return Env.baseUrl + "ads-detail/" + slug;
  };
  // const defaultImg = () => {
  //   const category = "";
  //   switch (category) {
  //     case "sponsored":
  //       return "/img/no-image-sponsored.jpg"
  //     default:
  //       return "/img/NoImage1.png"
  //   }
  // }
  return (
    <div className="w-full">
      <Navbar />
      <div className="w-full pt-36 lg:pt-24 bg-white flex justify-center mb-10">
        <div className="container flex flex-col gap-8">
          <div className="w-full flex flex-col lg:flex lg:flex-row gap-5 md:flex-row sm:flex-row">
            <div className="w-full lg:w-4/12 overflow-hidden border-2 shadow-lg rounded-lg md:w-1/2 ">
              <div className="flex justify-center items-center w-full min-h-fit md:h-full md:w-full">
                {adsDetailData.images && adsDetailData.images.length > 0 ? (
                  <img
                    src={adsDetailData.images[0].image_path}
                    className="w-full transition ease-in-out duration-700 group-hover:scale-110 transform hover:scale-105 lg:h-full object-cover"
                    onError={(e) => {
                      e.target.src = "/img/no-image.png";
                    }}
                    alt="Featured Image"
                  />
                ) : (
                  <img
                    src="/img/no-image.png"
                    alt="Default"
                    className="w-1/2 object-cover"
                  />
                )}
              </div>
            </div>
            <div className="w-full lg:w-8/12 border-2 shadow-lg rounded-lg md:w-3/4">
              <div className="p-4">
                <div className="text-2xl text-[#232D3B] font-semibold">
                  DETAILS
                </div>
                <div className="py-5 flex w-full">
                  <hr className="w-2/12 border border-blue-500"></hr>
                  <hr className="w-10/12 border border-slate-500"></hr>
                </div>
                <div className="text-xl lg:text-3xl text-[#234777] font-bold pb-4">
                  {adsDetailData?.title}
                </div>
                {/* <div className="text-2xl font-semibold text-[#01C6DA]">
                  {adsDetailData?.price} {adsDetailData.currency?.name}
                </div> */}
                <div className="text-xl font-medium flex gap-2 items-center py-4 text-slate-700">
                  <MdLocationOn />
                  {adsDetailData.location?.city},
                  {adsDetailData.location?.country_id}
                </div>
                <div className="flex gap-5 mb-5">
                  <div className="py-2 px-2 rounded-full bg-[#1873EB]">
                    <a
                      href={`https://www.facebook.com/sharer/sharer.php?u=${buildURL(
                        adsDetailData.slug
                      )}`}
                      target="_blank"
                      rel="noreferrer"
                      title="Share on Facebook"
                      className="relative"
                    >
                      <FaFacebookF color="#ffffff" />
                    </a>
                  </div>
                  <div className="py-2 px-2 rounded-full bg-[#199DF0]">
                    <a
                      href={`https://twitter.com/intent/tweet?url=${buildURL(
                        adsDetailData.slug
                      )}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <BsTwitter color="#ffffff" />
                    </a>
                  </div>
                  <div className="py-2 px-2 rounded-full bg-[#007AB5]">
                    <a
                      href={`https://www.linkedin.com/shareArticle?url=${buildURL(
                        adsDetailData.slug
                      )}`}
                      target="blank"
                    >
                      <FaLinkedinIn color="#ffffff" />
                    </a>
                  </div>
                  <div className="py-2 px-2 rounded-full bg-[#0E76A8]">
                    <a
                      href={`https://t.me/share/url?url=${buildURL(
                        adsDetailData.slug
                      )}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FaTelegram color="#ffffff" />
                    </a>
                  </div>
                  <div className="py-2 px-2 rounded-full bg-[#43BF51]">
                    <a
                      href={`whatsapp://send?text=${encodeURIComponent(
                        buildURL(adsDetailData.slug)
                      )}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FaWhatsapp color="#ffffff" />
                    </a>
                  </div>
                </div>
                <div className="py-2 flex gap-2 lg:gap-4">
                  <div>
                    {adsDetailData.is_has_store ? (
                      <Link
                        to={`/store/${adsDetailData.customer_id}`}
                        className="flex items-center gap-1 lg:gap-2 py-1 px-1  lg:py-2 lg:px-6 text-white text-sm lg:text-md rounded-md bg-[#234777] hover:bg-[#467dc9] transition duration-300"
                      >
                        <>
                          <span>
                            <FaStore />
                          </span>
                          <span>Store</span>
                        </>
                      </Link>
                    ) : (
                      <div className="flex items-center gap-1 lg:gap-2 py-1 px-1  lg:py-2 lg:px-6 text-white text-sm lg:text-md rounded-md bg-[#234777] transition duration-300 opacity-50">
                        <span>No Store</span>
                      </div>
                    )}
                  </div>
                  <div>
                    <button
                      onClick={() =>
                        addToFavourite(
                          adsDetailData.customer_id,
                          adsDetailData.listing_id
                        )
                      }
                      className="flex items-center gap-1 lg:gap-2 py-1 px-1  lg:py-2 lg:px-6 text-white text-sm lg:text-md rounded-md bg-[#234777] hover:bg-[#467dc9] transition duration-300"
                    >
                      <span>
                        <FaStar />
                      </span>
                      <span>Favorites</span>
                    </button>
                  </div>
                  <div>
                    <button className="flex items-center gap-1 lg:gap-2 py-1 px-1  lg:py-2 lg:px-8 text-white text-sm lg:text-md  rounded-md bg-[#234777] hover:bg-[#467dc9] transition duration-300">
                      <span>
                        <MdOutlineReportProblem />
                      </span>
                      <span>Report</span>
                    </button>
                  </div>
                  <div>
                    <a
                      href={`mailto:${adsDetailData.customer?.email}?subject=`}
                      target="_blank"
                      // onClick={handleShareButton}
                      className="flex items-center gap-1 lg:gap-2 py-1 px-1  lg:py-2 lg:px-9 text-white text-sm lg:text-md rounded-md bg-[#234777] hover:bg-[#467dc9] transition duration-300"
                      rel="noreferrer"
                    >
                      <span>
                        <AiFillMessage />
                      </span>
                      <span>Message</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full h-auto flex flex-col-reverse lg:flex lg:flex-row gap-5">
            <div className="w-full lg:w-8/12 rounded-lg">
              <div className="p-4 border-2 shadow-lg rounded-lg">
                <div className="text-2xl font-semibold">DESCRIPTION</div>
                <div className="py-5 flex w-full">
                  <hr className="w-2/12 border border-blue-600"></hr>
                  <hr className="w-10/12 border border-slate-500"></hr>
                </div>
                <div
                  className="font-medium text-slate-700"
                  dangerouslySetInnerHTML={{
                    __html:
                      adsDetailData?.description?.replace(/\\n/g, "<br>") || "",
                  }}
                />
              </div>
              <div className="pt-5 xl:mx-[-2rem]">
                <EbayAds />
              </div>
              <div className="pt-5 mx-[-2rem] mb-[-40px]">
                <PostNewAds />
              </div>
            </div>
            <div className="w-full lg:w-4/12 border-2 shadow-lg rounded-lg">
              <div className="p-4">
                <div className="text-2xl font-semibold">AREA MAP</div>
                <div className="py-5 flex w-full">
                  <hr className="w-2/12 border border-blue-500"></hr>
                  <hr className="w-10/12 border border-slate-500"></hr>
                </div>
                <div className="py-4">
                  <iframe
                    title="Map"
                    width="100%"
                    height="400"
                    style={{ border: 0 }}
                    src={`https://www.google.com/maps/embed/v1/place?key=${googleMapsApiKey}&q=${adsDetailData?.location?.latitude},${adsDetailData?.location?.longitude}`}
                    allowFullScreen
                  ></iframe>
                </div>
                <TopAffiliateOnAdsDetail />
              </div>
            </div>
          </div>
        </div>
      </div>
      {adsDetailData && (
        <RelatedSlider category={adsDetailData.category?.slug} />
      )}
      <BottomAds />
      <Footer />
    </div>
  );
}

export default FeaturedAdsDetail;
