import React, { useEffect, useState } from "react";
import { GrFormNextLink, GrFormPreviousLink } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import { getFavouriteAds } from "../slice/ListSlice";
import { Link } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { IoIosRemoveCircleOutline } from "react-icons/io";

const UserFavouriteAds = () => {
  const dispatch = useDispatch();
  const favouriteAdsData = useSelector((store) => store.ads.favouriteAds);
  const favouriteAds = favouriteAdsData?.data || [];

  // console.log(favouriteAds);

  const itemsPerPage = 5;
  const totalDataCount = favouriteAds?.total || 0;
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  useEffect(() => {
    dispatch(
      getFavouriteAds({
        skip: (currentPage - 1) * itemsPerPage,
        limit: itemsPerPage,
      })
    );
  }, [dispatch, currentPage]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= Math.ceil(totalDataCount / itemsPerPage)) {
      setCurrentPage(page);
    }
  };

  // const handleRemove = (adId) => {
  //   dispatch(removeFavouriteAd(adId));
  // };

  return (
    <div className="p-4">
      <div className="w-10/12 m-auto shadow-xl shadow-slate-200 rounded">
        <table className="min-w-full divide-y divide-gray-200 w-full">
          <thead>
            <tr>
              <th className="py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
              <th className="py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </th>
              <th className="py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Date
              </th>
              <th className="py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {favouriteAds.items?.map((item, index) => (
              <tr key={index} className="border-b">
                <td className="w-2/12 pb-2">
                  <img
                    src={item.listing?.images[0]?.image_path}
                    alt={item.listing.title}
                    className=""
                    onError={(e) => {
                      e.target.src = "/img/no-image.png";
                    }}
                  />
                </td>
                <td className="w-2/12">{item.listing.title}</td>
                <td className="w-2/12">{item.created_at}</td>
                <td className="w-2/12 h-40">
                  <tr className="flex justify-start gap-1">
                    <Link to={`/favourite-ads/${item.favorite_id}`} key={index}>
                      <button className="text-[#234777] flex justify-center items-center p-2 border px-2 hover:text-white hover:bg-[#234777]">
                        <FaEye className="mr-2" />
                        View
                      </button>
                    </Link>
                    <button className="text-red-900 flex justify-center items-center p-2 border px-2 hover:text-white hover:bg-red-600">
                      <IoIosRemoveCircleOutline className="mr-2" />
                      Remove
                    </button>
                  </tr>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-between py-6 m-auto max-w-[80%]">
          <div className="mt-2 text-lg">
            Showing{" "}
            <strong>
              {startIndex + 1}-{Math.min(endIndex, totalDataCount)}{" "}
            </strong>{" "}
            of <strong>{totalDataCount}</strong> results.
          </div>
          <div className="flex justify-between gap-4 pt-6 lg:py-0">
            <button
              className="flex justify-center items-center text-sm font-semibold border-2 rounded-md border-[#234777] hover:bg-[#234777] hover:text-white py-1 px-4"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
               <div>
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="text-xl " height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path className="hover:text-white" fill="currentColor" stroke="currentColor" stroke-width="2" d="M6,12.4 L18,12.4 M12.6,7 L18,12.4 L12.6,17.8" transform="matrix(-1 0 0 1 24 0)"></path></svg>
          </div>
              PREVIOUS
            </button>
            <button
              className="flex justify-center items-center text-sm font-semibold border-2 rounded-md border-[#234777] hover:bg-[#234777] hover:text-white py-1 px-4"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage * itemsPerPage >= totalDataCount}
            >
              NEXT
              <div>
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="text-xl" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path className="hover:text-white" fill="currentColor" stroke="currentColor" stroke-width="2" d="M6,12.4 L18,12.4 M12.6,7 L18,12.4 L12.6,17.8"></path></svg>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserFavouriteAds;
