import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBooks } from "../slice/BookSlice";
import { GrFormNextLink, GrFormPreviousLink } from "react-icons/gr";

const BookList = () => {
  const dispatch = useDispatch();
  const booksListData = useSelector((store) => {
    return store.book?.bookList;
  });
  const bookListRecords = booksListData?.data || [];

  const itemsPerPage = 20;
  const totalDataCount = bookListRecords?.total || 0;

  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const maxLength = 100;
  useEffect(() => {}, [totalDataCount]);
  useEffect(() => {
    dispatch(
      getBooks({
        skip: (currentPage - 1) * itemsPerPage,
        limit: itemsPerPage,
      })
    );
  }, [currentPage]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= Math.ceil(totalDataCount / itemsPerPage)) {
      setCurrentPage(page);
    }
  };
  return (
    <div className="relative pt-32 pb-10 lg:pt-20 w-11/12 mx-auto bg-transparent">
      <div className=" flex flex-col sm:flex-row  sm:flex justify-between py-5">
        <h1 className="text-blue-500 font-bold text-4xl">
          <span className="text-[#234777]">Book</span>
          <span className="text-[#01C6DA]"> List</span>
        </h1>
      </div>
      <hr className="border border-[#234777] mb-10" />
      <section className="p-5 md:p-0 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-5 items-start ">
        {bookListRecords?.items?.map((item, i) => (
          <section
            key={i}
            className="p-5 py-12 text-left transform duration-500 hover:-translate-y-2 hover:shadow-2xl cursor-pointer"
          >
            <div className="h-[300px]">
              <img
                className="p-5 h-[300px] mx-auto"
                src={item.image_url}
                alt=""
              />
            </div>
            {/* <h2 className="font-semibold mb-2 mt-12 text-cyan-600">Popular Collection</h2> */}
            <h1 className="text-3xl mb-5 h-16">{item.title}</h1>
            <p className="mb-5">{item.short_description}</p>
            <h2 className="text-xl font-semibold mb-5">${item.price}</h2>
            <a
              href={item.link_url}
              target="blank"
              className="p-2 px-6 bg-red-500 text-white rounded-md hover:bg-red-600"
            >
              See detail
            </a>
          </section>
        ))}
      </section>

      <div className="flex justify-between pt-10">
        <div className="flex justify-between gap-4 pt-6 lg:py-0">
          <button
            className="flex justify-center items-center text-sm font-semibold border-2 rounded-md border-[#234777] hover:bg-[#234777] hover:text-white py-1 px-4"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <div>
              <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="text-xl " height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path className="hover:text-white" fill="currentColor" stroke="currentColor" stroke-width="2" d="M6,12.4 L18,12.4 M12.6,7 L18,12.4 L12.6,17.8" transform="matrix(-1 0 0 1 24 0)"></path></svg>
            </div>
            PREVIOUS
          </button>
          <button
            className="flex justify-center items-center text-sm font-semibold border-2 rounded-md border-[#234777] hover:bg-[#234777] hover:text-white py-1 px-4"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage * itemsPerPage >= totalDataCount}
          >
            NEXT
            <div>
              <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="text-xl" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path className="hover:text-white" fill="currentColor" stroke="currentColor" stroke-width="2" d="M6,12.4 L18,12.4 M12.6,7 L18,12.4 L12.6,17.8"></path></svg>
            </div>
          </button>
        </div>

        <div className="mt-2 text-lg">
          Showing{" "}
          <strong>
            {startIndex + 1}-{Math.min(endIndex, totalDataCount)}{" "}
          </strong>{" "}
          of <strong>{totalDataCount}</strong> results.
        </div>
      </div>
    </div>
  );
};
export default BookList;
