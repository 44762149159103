import React, { useState } from "react";
// import {useHistory} from 'react-router-dom'
import Signin from "./Signin";
import Signup from "./Signup";

function UserForm() {
  const [showSignIn, SetShowSignIn] = useState(true);
  const [showSignUp, SetShowSignUp] = useState(false);
  // const history = useHistory()

  // const toggleForm = () => {
  //     // SetShowSignIn(!showSignIn);
  //     if(showSignIn){
  //         SetShowSignIn(false);
  //     }
  //     else{
  //         SetShowSignIn(true);
  //     }
  // }
  const showSignInForm = () => {
    SetShowSignIn(true);
    SetShowSignUp(false);
  };

  const showSignupForm = () => {
    SetShowSignIn(false);
    SetShowSignUp(true);
  };

  React.useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    if (queryParameters.get("tab") === "signup") {
      showSignupForm();
    }
  }, []);

  return (
    <div className="w-full flex justify-center scrollbar-hide overflow-hidden">
      <div className="w-full flex">
        <div
          className="w-7/12 h-screen hidden sm:hidden md:hidden lg:block bg-cover bg-center"
          style={{ backgroundImage: "url(/img/bg-login.jpg)" }}
        >
          <div className="flex justify-end relative">
          <div className="grid grid-cols-3 gap-10 w-1/2 absolute top-[300px]">
            <img
              src={"/img/login-logos/mgnit.jpeg"}
              className="rounded-full shadow-lg  top-[300px] left-[500px] opacity-50"
              alt=""
            ></img>
            <img
              src={"/img/login-logos/aisecs.jpeg"}
              className="rounded-full shadow-lg  top-[300px] left-[650px] opacity-50"
              alt=""
            ></img>
            <img
              src={"/img/login-logos/mgnit-gaming.jpeg"}
              className="rounded-full shadow-lg  top-[300px] left-[800px] opacity-50"
              alt=""
            ></img>
            <img
              src={"/img/login-logos/b2b.jpeg"}
              className="rounded-full shadow-lg  top-[450px] left-[500px] opacity-50"
              alt=""
            ></img>
            <img
              src={"/img/login-logos/book-writting.jpeg"}
              className="rounded-full shadow-lg  top-[450px] left-[650px] opacity-50"
              alt=""
            ></img>
            <img
              src={"/img/login-logos/logo-1.jpeg"}
              className="rounded-full shadow-lg  top-[450px] left-[800px] opacity-50"
              alt=""
            ></img>
            <img
              src={"/img/login-logos/recipe-bible.jpeg"}
              className="rounded-full shadow-lg  top-[600px] left-[500px] opacity-50"
              alt=""
            ></img>
            <img
              src={"/img/login-logos/world-news-online.jpeg"}
              className="rounded-full shadow-lg  top-[600px] left-[650px] opacity-50"
              alt=""
            ></img>
            <img
              src={"/img/login-logos/wwa.jpeg"}
              className="rounded-full shadow-lg  top-[600px] left-[800px] opacity-50"
              alt=""
            ></img>
          </div>
          </div>
          
        </div>
        <div className="w-full sm:w-full md:full lg:w-5/12">
          <div className="flex justify-around items-center w-full h-12">
            <div className="w-1/2 h-full text-center">
              <button
                onClick={showSignInForm}
                className={`w-full p-2 font-semibold text-lg ${
                  showSignIn
                    ? "bg-slate-300 text-blue-700 border-b-4 border-blue-700"
                    : ""
                }`}
              >
                SIGN IN
              </button>
            </div>
            <div className="w-1/2 h-full text-center ">
              <button
                onClick={showSignupForm}
                className={`w-full p-2 font-semibold text-lg ${
                  showSignUp
                    ? "bg-slate-300 text-blue-700 border-b-4 border-blue-700"
                    : ""
                }`}
              >
                SIGN UP
              </button>
            </div>
          </div>

          <div className="scrollbar-hide overflow-hidden">
            {showSignIn && <Signin showSignupForm={showSignupForm} />}
            {showSignUp && <Signup showSignInForm={showSignInForm} />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserForm;
