import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteAds, getMyAds } from "../slice/ListSlice";
import { GrFormNextLink, GrFormPreviousLink } from "react-icons/gr";
import {
  FaEye,
  FaFacebookF,
  FaRegEnvelope,
  FaRegHeart,
  FaTwitter,
  FaWhatsapp,
} from "react-icons/fa";
import { MdCall } from "react-icons/md";
import EditAdsOverlay from './EditAdsOverlay';
import toast from "react-hot-toast";

const MyAds = () => {
  const dispatch = useDispatch();
  const customerId = useSelector((store) => store.auth.customerId);
  const MyAdsData = useSelector((store) => store.ads?.myAds);
  const adsData = MyAdsData?.data || [];
  const [isEditOverlayOpen, setEditOverlayOpen] = useState(false);
  const [selected, setSelected] = useState();


  const itemsPerPage = 10;
  const totalDataCount = adsData?.total || 0;
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const handlePageChange = (page) => {
    if (page >= 1 && page <= Math.ceil(totalDataCount / itemsPerPage)) {
      setCurrentPage(page);
    }
  };

  useEffect(() => {
    dispatch(
      getMyAds({
        id: customerId,
        status: "active",
        skip: (currentPage - 1) * itemsPerPage,
        limit: itemsPerPage,
      })
    );
  }, [dispatch, currentPage,customerId]);

  const [expandedAdId, setExpandedAdId] = useState(null);

  const handleExpand = (adId) => {
    setExpandedAdId(adId === expandedAdId ? null : adId);
  };

  const exportAdsData = () => {
    alert("data dounloading");
  };

  const handleWhatsApp = (adId) => {};

  const handleFacebook = (adId) => {};

  const handleTwitter = (adId) => {};

  const handleDelete = async (adId) => {
    try {
      await dispatch(
        deleteAds(adId)
      ).unwrap()
      toast.success("Ads has been deleted");
      dispatch(
        getMyAds({
          id: customerId,
          status: "active",
          skip: (currentPage - 1) * itemsPerPage,
          limit: itemsPerPage,
        })
      );
    } catch (error) {
      toast.error(error?.message || error);
    }
  };

  const handleEdit = (data) => {
    setSelected(data);
    setTimeout(() => {
      setEditOverlayOpen(true)
    }, 100);
  };
  const handleEditClose = () => {
    setEditOverlayOpen(false);
    dispatch(
      getMyAds({
        id: customerId,
        status: "active",
        skip: (currentPage - 1) * itemsPerPage,
        limit: itemsPerPage,
      })
    );
  }
  const handleDisable = (adId) => {};

  return (
    <div className="">
      {adsData?.items?.length > 0 ? (
        <div className=" flex flex-col justify-center w-10/12 m-auto ">
          <table className=" divide-y divide-gray-200 table-auto border shadow-xl">
            <thead>
              <tr>
                <th className="py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
                <th className="py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Name
                </th>
                <th className="py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Category
                </th>
                <th className="py-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider ">
                  Status
                </th>
                <th
                  className="py-4  text-xs font-medium text-gray-500 uppercase cursor-pointer"
                  onClick={exportAdsData}
                >
                  Export Ads Data
                </th>
              </tr>
            </thead>
            <tbody>
              {adsData?.items?.map((ad, index) => (
                <React.Fragment key={index}>
                  <tr className="border-b hover:bg-slate- duration-300">
                    <td className="w-2/12">
                      <img
                        src={ad?.images[0]?.image_path}
                        alt={ad?.title}
                        className="pb-2"
                      />
                    </td>
                    <td className="w-2/12 px-4 ">{ad?.title}</td>
                    <td className="w-2/12 ">{ad?.category?.name}</td>
                    <td className="1/12">{ad?.status}</td>
                    <td className="w-5/12">
                      <tr className="flex justify-center gap-1 my-2">
                        <button
                          className="text-black font-semibold text-xs bg-[#EEEEEE] border h-14 w-14 flex justify-center items-center hover:text-indigo-900"
                          onClick={() => handleExpand(ad.id)}
                        >
                          STATS
                        </button>

                        <button
                          className="text-white font-semibold text-xs bg-[#B1B1B1] border h-14 w-14 flex justify-center items-center hover:text-red-900"
                          onClick={() => handleDelete(ad.listing_id)}
                        >
                          DELETE
                        </button>
                        <button
                          className="text-white font-semibold text-xs bg-[#1ABC9C] border h-14 w-14 flex justify-center items-center hover:text-indigo-900"
                          onClick={() => handleEdit(ad)}
                        >
                          EDIT
                        </button>
                        <button
                          className="text-white font-semibold text-xs bg-[#FF5252] border h-14 w-14 flex justify-center items-center hover:text-yellow-900"
                          onClick={() => handleDisable(ad.id)}
                        >
                          DISABLE
                        </button>
                        {/* <button
                          className="text-white font-semibold text-lg bg-[#25D366] border h-14 w-14 flex justify-center items-center hover:text-green-900"
                          onClick={() => handleWhatsApp(ad.id)}
                        >
                          <FaWhatsapp />
                        </button>
                        <button
                          className="text-white font-semibold text-lg bg-[#4267B2] border h-14 w-14 flex justify-center items-center hover:text-blue-900"
                          onClick={() => handleFacebook(ad.id)}
                        >
                          <FaFacebookF />
                        </button>
                        <button
                          className="text-white font-semibold text-lg bg-[#00ACEE]  border h-14 w-14 flex justify-center items-center hover:text-twitter-900"
                          onClick={() => handleTwitter(ad.id)}
                        >
                          <FaTwitter />
                        </button> */}
                      </tr>
                    </td>
                  </tr>
                  {expandedAdId === ad.id && (
                    <tr className="w-full transition-all duration-300 ease-in-out expanded-row bg-[#F2F9FC] border-b">
                      <td colSpan="5" className="w-full p-10">
                        <div className="flex justify-between ">
                          <p className="flex items-center">
                            Total views:
                            <span className="flex justify-between items-center px-5">
                              <FaEye />
                              {ad?.totalViews}
                            </span>
                          </p>
                          <p className="flex items-center">
                            Shared On:
                            <span className="flex justify-between items-center px-5">
                              <FaFacebookF className="mr-2" />
                              {ad?.sharedOn?.facebook}
                            </span>
                            <span className="flex justify-between items-center px-5">
                              <FaTwitter className="mr-2" />
                              {ad?.sharedOn?.twitter}
                            </span>
                            <span className="flex justify-between items-center px-5">
                              <FaRegEnvelope className="mr-2" />
                              {ad?.sharedOn?.whatsapp}
                            </span>
                          </p>
                          <p className="flex items-center">
                            Add to Favorite:{" "}
                            <span className="flex justify-between items-center px-5">
                              <FaRegHeart className="mr-2" />
                              {ad?.addToFavorite}
                            </span>
                          </p>
                          <p className="flex items-center">
                            Views On:
                            <span className="flex justify-between items-center px-5">
                              <MdCall className="mr-2" />
                              {ad?.viewsOn?.call}
                            </span>
                            <span className="flex justify-between items-center px-5">
                              <FaRegEnvelope className="mr-2" />
                              {ad?.viewsOn?.mail}
                            </span>
                          </p>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
          <div className="pt-8 md:pt-0 flex justify-end gap-5 py-2  mt-5">
            <button
              className="flex justify-center items-center text-sm font-semibold border-2 rounded-md border-[#234777] hover:bg-[#234777] hover:text-white py-1 px-4"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <div>
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="text-xl " height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path className="hover:text-white" fill="currentColor" stroke="currentColor" stroke-width="2" d="M6,12.4 L18,12.4 M12.6,7 L18,12.4 L12.6,17.8" transform="matrix(-1 0 0 1 24 0)"></path></svg>
              </div>
              PREVIOUS
            </button>
            <button
              className="flex justify-center items-center text-sm font-semibold border-2 rounded-md border-[#234777] hover:bg-[#234777] hover:text-white py-1 px-4"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage * itemsPerPage >= totalDataCount}
            >
              NEXT
              <div>
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="text-xl" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path className="hover:text-white" fill="currentColor" stroke="currentColor" stroke-width="2" d="M6,12.4 L18,12.4 M12.6,7 L18,12.4 L12.6,17.8"></path></svg>
              </div>
            </button>
          </div>
        </div>
      ) : (
        <div className="flex  flex-col justify-center  lg:min-h-[24vh] md:min-h-[30vh] xl:min-h-[27vh] 2xl:min-h-[34.8vh">
          <div className=" text-[#01c6da] border-2 border-[#234777] rounded-md py-10 text-center">
            {" "}
            <span className="text-2xl italic">No results found</span>
          </div>
        </div>
      )}
      {isEditOverlayOpen && (
        <EditAdsOverlay onClose={handleEditClose} data={selected} />
      )}
    </div>
  );
};

export default MyAds;
