/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  FaBuilding,
  FaCamera,
  FaLocationArrow,
  FaRegStar,
  FaStar,
  FaTags,
} from "react-icons/fa";
import EditStoreOverlay from "./EditStoreOverlay";
import EditBannerStoreOverlay from "./EditBannerStoreOverlay";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation, Link, useNavigate } from "react-router-dom";
import { getStore, getStoreAds } from "../slice/StoreSlice";
import { creatFavouriteAds, getMyAds } from "../slice/ListSlice";
import { GrFormPreviousLink, GrFormNextLink } from "react-icons/gr";
import { MdLocationOn } from "react-icons/md";
import { BiExitFullscreen } from "react-icons/bi";
import { HiOutlineStatusOnline } from "react-icons/hi";
import MyBanner from "./MyBanner";

function MyStoreAds() {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [liked, setLiked] = useState({});

  const { slug } = useParams();
  const location = useLocation();
  const { storeDetail, error } = useSelector((store) => store.store);
  const storeAds = useSelector((store) => store.store.storeAds);
  const [storeDetailData, setStoreDetailData] = useState(
    storeDetail?.data || null
  );
  const [storeAdsData, setStoreAdsData] = useState(storeAds?.data || null);

  const itemsPerPage = 6;
  const totalDataCount = storeAdsData?.total || 0;
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  // const maxLength = 100;

  const handlePageChange = (page) => {
    if (page >= 1 && page <= Math.ceil(totalDataCount / itemsPerPage)) {
      setCurrentPage(page);
    }
  };
  const init = async () => {
    try {
      await dispatch(
        getStore({
          customer_id: slug ?? "",
        })
      ).unwrap();
    } catch (error) {
      if (error?.message === "Unauthenticated.") {
        navigate("/login");
      }
    }
  };
  useEffect(() => {
    setCurrentPage(1);
    init();
  }, [slug]);
  useEffect(() => {
    if (storeDetailData?.customer_id) {
      dispatch(
        getStoreAds({
          skip: (currentPage - 1) * itemsPerPage,
          limit: itemsPerPage,
          customer_id: storeDetailData?.customer_id ?? "",
        })
      );
    }
  }, [currentPage, storeDetailData?.customer_id]);

  useEffect(() => {
    if (error === "Unauthenticated.") {
      navigate("/Login");
    }
    setStoreDetailData(storeDetail?.data);
  }, [storeDetail, slug]);

  useEffect(() => {
    setStoreAdsData(storeAds?.data);
  }, [storeAds]);

  const truncateString = (str, maxLength) => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    }
    return str;
  };

  const addToFavourite = (customer_id, listing_id, index) => {
    if (liked[index]) {
      dispatch(
        creatFavouriteAds({
          data: { customer_id, listing_id, is_favorite: false },
        })
      );
    } else {
      dispatch(
        creatFavouriteAds({
          data: { customer_id, listing_id, is_favorite: true },
        })
      );
    }

    setLiked((prevLiked) => ({
      ...prevLiked,
      [index]: !prevLiked[index],
    }));
  };
  const [isEditOverlayOpen, setEditOverlayOpen] = useState(false);
  const [isEditBannerOverlayOpen, setEditBannerOverlayOpen] = useState(false);

  // Handler to open the edit overlay
  const handleEditClick = () => {
    setEditOverlayOpen(true);
  };
  const handleEditBannerClick = () => {
    setEditBannerOverlayOpen(true);
  };

  // Handler to close the edit overlay
  const handleEditClose = () => {
    init();
    setEditOverlayOpen(false);
    setEditBannerOverlayOpen(false);
  };

  return (
    <div className="pt-32 pb-10 lg:pt-24 bg-gray-200 flex justify-center">
      <div className="w-10/12">
        <div className="text-3xl font-bold py-4">Store Details</div>
        <div className=" flex flex-col items-center">
          <div className="w-full border-2 ">
            <div className="relative">
              <img
                src={"/img/bg-default-banner-store.jpg"}
                className="w-full h-[200px] object-cover rounded-t-3xl"
                alt=""
              />
              {!slug && (
                <button
                  onClick={handleEditBannerClick}
                  className="absolute top-5 right-5 ring-2 ring-white rounded-full p-1 bg-white hover:text-blue-600"
                >
                  <FaCamera />
                </button>
              )}
            </div>
            <div className="p-5 bg-white rounded-b-3xl">
              <div className="flex items-start justify-between w-full text-lg font-semibold pb-5">
                <div className="flex mt-[-75px] z-10 rounded-full mbs-[-30px] mli-[-5px] border-[5px] border-be-0 h-[120px] w-[120px]">
                  <img
                    height="120"
                    width="120"
                    src={
                      storeDetailData?.store_logo
                        ? storeDetailData?.store_logo
                        : "/img/no-image-available.jpg"
                    }
                    // src="/img/noImage.png"
                    className="rounded-full object-cover"
                    alt="Company Logo"
                  />
                </div>
                {location.pathname.includes("my-store") && (
                  <button onClick={handleEditClick} className="text-blue-700">
                    {storeDetailData?.store_id ? "Edit" : "Upgrade"}
                  </button>
                )}
              </div>
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                <div className="flex items-center gap-5">
                  <div className="bg-gray-200 p-5 rounded-full">
                    <FaBuilding className="h-5 w-5" />
                  </div>
                  <div>
                    <div className="text-slate-400 font-semibold">
                      Store Name
                    </div>
                    <div className="text-lg text-slate-900 font-semibold">
                      {storeDetailData?.store_name}
                    </div>
                  </div>
                </div>
                <div className="flex items-center gap-5">
                  <div className="bg-gray-200 p-5 rounded-full">
                    <FaLocationArrow className="h-5 w-5" />
                  </div>
                  <div>
                    <div className="text-slate-400 font-semibold">
                      Store Address
                    </div>
                    <div className="text-lg text-slate-900 font-semibold">
                      {storeDetailData?.store_address}
                    </div>
                  </div>
                </div>
                {/* <div className="flex items-center gap-5">
                  <div className="bg-gray-200 p-5 rounded-full">
                    <FaBuilding className="h-5 w-5" />
                  </div>
                  <div>
                    <div className="text-slate-400 font-semibold">
                      Company No.
                    </div>
                    <div className="text-lg text-slate-900 font-semibold">
                      {storeDetailData?.company_no ?? ""}
                    </div>
                  </div>
                </div> */}
                {/* <div className="flex items-center gap-5">
                  <div className="bg-gray-200 p-5 rounded-full">
                    <FaBuilding className="h-5 w-5" />
                  </div>
                  <div>
                    <div className="text-slate-400 font-semibold">VAT</div>
                    <div className="text-lg text-slate-900 font-semibold">
                      {storeDetailData?.vat}
                    </div>
                  </div>
                </div> */}
                <div className="flex items-center gap-5">
                  <div className="bg-gray-200 p-5 rounded-full">
                    <HiOutlineStatusOnline className="h-5 w-5" />
                  </div>
                  <div>
                    <div className="text-slate-400 font-semibold">Status</div>
                    <div className="text-lg text-slate-900 font-semibold">
                      {storeDetailData?.status === "active"
                        ? "ACTIVE"
                        : "DISABLE"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full grid grid-cols-1 md:grid-cols-12 gap-5">
            <div className="w-full col-span-8">
              <div className="text-3xl font-bold py-4">
                {slug ? "Store Ads" : "My Ads"}
              </div>
              <div className="flex justify-between gap-4 pt-6 lg:py-0 mb-5">
                <button
                  className="flex justify-center items-center text-sm font-semibold border-2 rounded-md border-[#234777] hover:bg-[#234777] hover:text-white py-1 px-4"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  <div>
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="text-xl " height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path className="hover:text-white" fill="currentColor" stroke="currentColor" stroke-width="2" d="M6,12.4 L18,12.4 M12.6,7 L18,12.4 L12.6,17.8" transform="matrix(-1 0 0 1 24 0)"></path></svg>
          </div>
                  PREVIOUS
                </button>
                <button
                  className="flex justify-center items-center text-sm font-semibold border-2 rounded-md border-[#234777] hover:bg-[#234777] hover:text-white py-1 px-4"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage * itemsPerPage >= totalDataCount}
                >
                  NEXT
                  <div>
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="text-xl" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path className="hover:text-white" fill="currentColor" stroke="currentColor" stroke-width="2" d="M6,12.4 L18,12.4 M12.6,7 L18,12.4 L12.6,17.8"></path></svg>
          </div>
                </button>
              </div>
              {storeAdsData?.items?.length > 0 ? (
                <div className="rounded-3xl p-5 grid grid-cols-1 gap-4 sm:grid-cols-2 sm:justify-center md:grid-cols-2 lg:grid-cols-3 lg:justify-between ">
                  {storeAdsData?.items.map((items, index) => (
                    <div
                      key={index}
                      className="group flex flex-col w-full overflow-hidden bg-white border border-slate-200 shadow-lg rounded-lg"
                    >
                      <Link to={`/ads-detail/${items.slug}`}>
                        <div className="h-48 flex justify-center items-center">
                          {items.images && items.images.length > 0 ? (
                            <img
                              src={items.images[0]?.image_path}
                              alt={items.title}
                              onError={(e) => {
                                e.target.src = "/img/no-image.png";
                              }}
                              className="h-full w-full object-cover"
                            />
                          ) : (
                            <img
                              src="/img/no-image.png"
                              alt="Default"
                              className="w-1/2 object-cover"
                            />
                          )}
                        </div>
                      </Link>

                      <div className="flex flex-col flex-1 p-4">
                        <div className="flex flex-col flex-1">
                          <div className="flex items-center gap-1 text-[#999999] pb-2">
                            <div>
                              <FaTags />
                            </div>
                            <div>{items.category.name}</div>
                          </div>
                          <div>
                            <hr className="border-1 text-[#999999]" />
                          </div>
                          <div className="text-lg text-[#232D3B] py-1">
                            <Link
                              to={`/ads-detail/${items.slug}`}
                              className="font-bold group-hover:text-yellow-500"
                            >
                              {truncateString(items.title, 30)}
                            </Link>
                          </div>
                          <div className="flex items-start gap-1 pb-2 text-[#999999]">
                            <div className="pt-1">
                              <MdLocationOn />
                            </div>
                            <div className="">{items.location.city}</div>
                          </div>
                        </div>
                        <div>
                          <hr className="border-1 text-[#999999]" />
                        </div>
                        <div className="flex justify-end align-middle font-bold text-[#232D3B] text-lg py-2">
                          {/* <div className="font-bold text-[#232D3B]  w-7/12">
                            {items.price}
                          </div> */}
                          <div className=" w-3/12 flex justify-end">
                            <div className=" border-l-2 border-slate-200">
                              <Link
                                to={`/ads-detail/${items.slug}`}
                                key={index}
                              >
                                <button className="p-1 hover:scale-110 duration-300">
                                  <BiExitFullscreen className="w-5 h-5" />
                                </button>
                              </Link>
                            </div>
                            <div className=" border-l-2 border-slate-200">
                              <button
                                className="p-1 hover:scale-110 duration-300"
                                onClick={() =>
                                  addToFavourite(
                                    items.customer_id,
                                    items.listing_id,
                                    index
                                  )
                                }
                              >
                                {liked[index] ? (
                                  <FaStar className="text-yellow-400 w-5 h-5" />
                                ) : (
                                  <FaRegStar className="w-5 h-5" />
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p>My ads not Available</p>
              )}
              <div className="flex justify-between py-6">
                <div className="mt-2 text-lg">
                  Showing{" "}
                  <strong>
                    {startIndex + 1}-{Math.min(endIndex, totalDataCount)}{" "}
                  </strong>{" "}
                  of <strong>{totalDataCount}</strong> results.
                </div>
                <div className="flex justify-between gap-4 pt-6 lg:py-0">
                  <button
                    className="flex justify-center items-center text-sm font-semibold border-2 rounded-md border-[#234777] hover:bg-[#234777] hover:text-white py-1 px-4"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    <div>
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="text-xl " height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path className="hover:text-white" fill="currentColor" stroke="currentColor" stroke-width="2" d="M6,12.4 L18,12.4 M12.6,7 L18,12.4 L12.6,17.8" transform="matrix(-1 0 0 1 24 0)"></path></svg>
          </div>
                    PREVIOUS
                  </button>
                  <button
                    className="flex justify-center items-center text-sm font-semibold border-2 rounded-md border-[#234777] hover:bg-[#234777] hover:text-white py-1 px-4"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage * itemsPerPage >= totalDataCount}
                  >
                    NEXT
                    <div>
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="text-xl" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path className="hover:text-white" fill="currentColor" stroke="currentColor" stroke-width="2" d="M6,12.4 L18,12.4 M12.6,7 L18,12.4 L12.6,17.8"></path></svg>
          </div>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-span-4">
              <MyBanner customerId={slug} />
            </div>
          </div>
        </div>
      </div>

      {/* Display the EditOverlay component when isEditOverlayOpen is true */}
      {isEditOverlayOpen && (
        <EditStoreOverlay onClose={handleEditClose} data={storeDetailData} />
      )}
      {isEditBannerOverlayOpen && (
        <EditBannerStoreOverlay
          onClose={handleEditClose}
          imageBanner={""}
          type={"store"}
        />
      )}
    </div>
  );
}

export default MyStoreAds;
