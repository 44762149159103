import React, { useEffect, useState } from "react";
import {
  GrFormPreviousLink,
  GrFormNextLink,
  GrPrevious,
  GrNext,
} from "react-icons/gr";
import PrevNextbtn from "./PrevNextbtn";
import { FaTags } from "react-icons/fa";
import { MdFullscreenExit, MdLocationOn } from "react-icons/md";
import { Link } from "react-router-dom";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { BiExitFullscreen } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { getAffiliateList } from "../slice/AffiliateSLice";

const AffiliateAds = () => {
  const dispatch = useDispatch();
  const affiliateData = useSelector((store) => {
    return store.aff.affiliateList;
  });
  const affiliatesAds = affiliateData?.data || [];
  const itemsPerPage = 42;
  const totalDataCount = affiliatesAds?.total || 0;
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const maxLength = 100;

  const truncateString = (str, maxLength) => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    }
    return str;
  };

  const handlePageChange = (page) => {
    if (page >= 1 && page <= Math.ceil(totalDataCount / itemsPerPage)) {
      setCurrentPage(page);
    }
  };

  useEffect(() => {
    dispatch(
      getAffiliateList({
        position: "",
        skip: (currentPage - 1) * itemsPerPage,
        limit: itemsPerPage,
      })
    );
  }, [currentPage]);

  return (
    <div className=" relative mt-32 lg:mt-20 w-11/12 mx-auto bg-transparent">
      <div className=" flex flex-col justify-between py-5">
        <h1 className="text-blue-500 font-bold text-4xl mb-10">
          <span className="text-[#234777]">Affiliates</span>
          <span className="text-[#01C6DA]"> Ads</span>
        </h1>
        <div className="pt-8 md:pt-0 flex justify-between gap-5 py-2">
          <button
            className="flex justify-center items-center text-sm font-semibold border-2 rounded-md border-[#234777] hover:bg-[#234777] hover:text-white py-1 px-4"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <div>
              <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="text-xl " height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path className="hover:text-white" fill="currentColor" stroke="currentColor" stroke-width="2" d="M6,12.4 L18,12.4 M12.6,7 L18,12.4 L12.6,17.8" transform="matrix(-1 0 0 1 24 0)"></path></svg>
            </div>
            PREVIOUS
          </button>
          <button
            className="flex justify-center items-center text-sm font-semibold border-2 rounded-md border-[#234777] hover:bg-[#234777] hover:text-white py-1 px-4"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage * itemsPerPage >= totalDataCount}
          >
            NEXT
            <div>
              <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="text-xl" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path className="hover:text-white" fill="currentColor" stroke="currentColor" stroke-width="2" d="M6,12.4 L18,12.4 M12.6,7 L18,12.4 L12.6,17.8"></path></svg>
            </div>
          </button>
        </div>
      </div>
      {/* <div className="bg-yellow-400 flex flex-wrap  mt-10 w-full "> */}
      {affiliatesAds.items?.length > 0 ? (
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:justify-center md:grid-cols-2 lg:grid-cols-6 lg:justify-between ">
          {affiliatesAds.items?.map((items, index) => (
            <a
              href={`/affiliate?url=${items.link}`}
              rel="noreferrer"
              target="_blank"
              className="group"
              key={index}
            >
              {items.image_url ? (
                <div className="w-full overflow-hidden border border-slate-200 shadow-lg rounded-lg hover:text-yellow-500">
                  <div className="h-44">
                    {items.image_url ? (
                      <img
                        className="transform duration-500 hover:scale-105 h-full w-full object-cover"
                        src={items.image_url}
                        alt={items.title}
                        onError={(e) => {
                          e.target.src = "/img/no-image.png";
                        }}
                      />
                    ) : (
                      <img
                        className="transform duration-500 hover:scale-105 h-full w-full object-cover"
                        src={"/img/no-image.png"}
                        alt="Default"
                      />
                    )}
                  </div>
                  <div className="p-4">
                    <div className="text-base text-[#232D3B] py-1 flex-grow group-hover:text-yellow-500">
                      <div>
                        <span className="line-clamp-2">{items.title}</span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className=" w-full rounded-lg h-full bg-gradient-to-r from-blue-900 to-cyan-700 shadow-lg">
                  <div className="flex h-full p-4">
                    <div className="flex flex-grow h-full items-center">
                      <Link
                        target="_blank"
                        rel="noreferrer"
                        to={`/affiliate?url=${items.link}`}
                        className="text-base text-white text-ellipsis overflow-hidden group-hover:text-yellow-500 line-clamp-6"
                      >
                        {items.title}
                      </Link>
                    </div>
                  </div>
                </div>
                // <div className="flex rounded-lg h-full bg-gradient-to-r from-blue-900 to-cyan-700 p-4 flex-col">
                //   <div className="flex items-center mb-3">
                //     <div className="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-indigo-700 text-white flex-shrink-0">
                //       <svg
                //         fill="none"
                //         stroke="currentColor"
                //         stroke-linecap="round"
                //         stroke-linejoin="round"
                //         stroke-width="2"
                //         className="w-5 h-5"
                //         viewBox="0 0 24 24"
                //       >
                //         <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                //       </svg>
                //     </div>
                //     <h2 className="text-white text-lg font-medium">
                //       Affiliate
                //     </h2>
                //   </div>
                //   <div className="flex flex-col justify-between flex-grow">
                //     <p className="text-sm text-white text-ellipsis overflow-hidden">
                //       {items.title}
                //     </p>
                //   </div>
                // </div>
              )}
            </a>
          ))}
        </div>
      ) : (
        <div className="flex  flex-col justify-center  lg:min-h-[24vh] md:min-h-[30vh] xl:min-h-[27vh] 2xl:min-h-[34.8vh">
          <div className=" text-[#01c6da] border-2 border-[#234777] rounded-md py-10 text-center">
            {" "}
            <span className="text-2xl italic">No results found</span>
          </div>
        </div>
      )}
      <div className="flex justify-between py-6">
        <div className="mt-2 text-lg">
          Showing{" "}
          <strong>
            {startIndex + 1}-{Math.min(endIndex, totalDataCount)}{" "}
          </strong>{" "}
          of <strong>{totalDataCount}</strong> results.
        </div>
        <div className="flex justify-between gap-4 pt-6 lg:py-0">
          <button
            className="flex justify-center items-center text-sm font-semibold border-2 rounded-md border-[#234777] hover:bg-[#234777] hover:text-white py-1 px-4"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <div>
              <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="text-xl " height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path className="hover:text-white" fill="currentColor" stroke="currentColor" stroke-width="2" d="M6,12.4 L18,12.4 M12.6,7 L18,12.4 L12.6,17.8" transform="matrix(-1 0 0 1 24 0)"></path></svg>
            </div>
            PREVIOUS
          </button>
          <button
            className="flex justify-center items-center text-sm font-semibold border-2 rounded-md border-[#234777] hover:bg-[#234777] hover:text-white py-1 px-4"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage * itemsPerPage >= totalDataCount}
          >
            NEXT
            <div>
              <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="text-xl" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path className="hover:text-white" fill="currentColor" stroke="currentColor" stroke-width="2" d="M6,12.4 L18,12.4 M12.6,7 L18,12.4 L12.6,17.8"></path></svg>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AffiliateAds;
