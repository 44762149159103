import React, { useEffect, useState } from "react";
import {
  GrPrevious,
  GrNext,
  GrFormNextLink,
  GrFormPreviousLink,
} from "react-icons/gr";
import PrevNextbtn from "./PrevNextbtn";
import { FaRegStar, FaStar, FaTags } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";
import { Link } from "react-router-dom";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { BiExitFullscreen } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import {
  getFavouriteAds,
  removeFabAds,
  updateFavAdsList,
} from "../slice/ListSlice";

const FavoriteAds = () => {
  const dispatch = useDispatch();
  const [removeMessage, setRemoveMessage] = useState("");
  const favouriteAdsData = useSelector((store) => store.ads.favouriteAds);
  const favouriteAds = favouriteAdsData?.data || [];

  const itemsPerPage = 10;
  const totalDataCount = favouriteAds?.total || 0;
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const truncateString = (str, maxLength) => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    }
    return str;
  };

  const handlePageChange = (page) => {
    if (page >= 1 && page <= Math.ceil(totalDataCount / itemsPerPage)) {
      setCurrentPage(page);
    }
  };

  useEffect(() => {
    dispatch(
      getFavouriteAds({
        skip: (currentPage - 1) * itemsPerPage,
        limit: itemsPerPage,
      })
    );
  }, [dispatch, currentPage]);

  const handleRemoveFromFavorites = (favouriteId) => {
    dispatch(removeFabAds({ id: favouriteId }));
    setRemoveMessage("Removed  successfully");
  };

  return (
    <div className=" relative pt-32 lg:pt-20 w-11/12 mx-auto bg-transparent">
      <div className=" flex flex-col sm:flex-row  sm:flex justify-between py-5">
        <h1 className="text-blue-500 font-bold text-4xl">
          <span className="text-[#234777]">Favorites</span>
          <span className="text-[#01C6DA]"> Ads</span>
        </h1>
        <div className="pt-8 md:pt-0 flex justify-between gap-5 py-2">
          <button
            className="flex justify-center items-center text-sm font-semibold border-2 rounded-md border-[#234777] hover:bg-[#234777] hover:text-white py-1 px-4"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <div>
              <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="text-xl " height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path className="hover:text-white" fill="currentColor" stroke="currentColor" stroke-width="2" d="M6,12.4 L18,12.4 M12.6,7 L18,12.4 L12.6,17.8" transform="matrix(-1 0 0 1 24 0)"></path></svg>
            </div>
            PREVIOUS
          </button>
          <button
            className="flex justify-center items-center text-sm font-semibold border-2 rounded-md border-[#234777] hover:bg-[#234777] hover:text-white py-1 px-4"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage * itemsPerPage >= totalDataCount}
          >
            NEXT
            <div>
              <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="text-xl" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path className="hover:text-white" fill="currentColor" stroke="currentColor" stroke-width="2" d="M6,12.4 L18,12.4 M12.6,7 L18,12.4 L12.6,17.8"></path></svg>
            </div>
          </button>
        </div>
      </div>
      {favouriteAds.items?.length > 0 ? (
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:justify-center md:grid-cols-2 lg:grid-cols-5 lg:justify-between ">
          {favouriteAds.items?.map((item, index) => (
            <div
              key={index}
              className=" h-[400px] w-full overflow-hidden border border-slate-200 shadow-lg rounded-lg"
            >
              <div className="w-full max-h-56 min-h-fit flex justify-center items-center">
                <img
                  className="transition ease-in-out duration-700 group-hover:scale-110 w-full transform hover:scale-105 object-fill max-h-56"
                  src={item.listing.images[0]?.image_path}
                  alt={item.listing.title}
                  onError={(e) => {
                    e.target.src = "/img/no-image.png";
                    // e.target.style.width = "50%";
                  }}
                />
              </div>
              <div className="p-4">
                <div className="flex items-center gap-1 text-[#999999] pb-2">
                  <div>
                    <FaTags />
                  </div>
                  <div>{item.listing.category.name}</div>
                </div>
                <div>
                  <hr className="border-1 text-[#999999]" />
                </div>
                <div className="text-lg text-[#232D3B] py-1">
                  <div className="font-bold">
                    {truncateString(item.listing.title, 22)}
                  </div>
                </div>
                <div className="flex items-start gap-1 pb-2 text-[#999999]">
                  <div className="pt-1">
                    <MdLocationOn />
                  </div>
                  <div className="">{item.listing.location.city}</div>
                </div>
                <div>
                  <hr className="border-1 text-[#999999]" />
                </div>
                <div className="flex justify-between align-middle font-bold text-[#232D3B] text-lg py-2">
                  {/* <div className="w-7/12">{item.listing.price}</div> */}
                  <div className=" w-3/12 flex justify-end">
                    <div className=" border-l-2 border-slate-200">
                      <Link
                        to={`/favourite-ads/${item.favorite_id}`}
                        key={index}
                      >
                        <button className="p-1 hover:scale-110 duration-300">
                          <BiExitFullscreen className="w-5 h-5" />
                        </button>
                      </Link>
                    </div>
                    <div className=" border-l-2 border-slate-200">
                      <button
                        className="p-1 hover:scale-110 duration-300"
                        onClick={() =>
                          handleRemoveFromFavorites(item.favorite_id)
                        }
                      >
                        {item.favorite_id ? (
                          <FaStar className="text-yellow-400 w-5 h-5" />
                        ) : (
                          <FaRegStar className="w-5 h-5" />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex  flex-col justify-center  lg:min-h-[24vh] md:min-h-[30vh] xl:min-h-[27vh] 2xl:min-h-[34.8vh">
          <div className=" text-[#01c6da] border-2 border-[#234777] rounded-md py-10 text-center">
            {" "}
            <span className="text-2xl italic">No results found</span>
          </div>
        </div>
      )}
      {removeMessage && <div className="text-green-500">{removeMessage}</div>}
      <div className="flex justify-between py-6">
        <div className="mt-2 text-lg">
          Showing{" "}
          <strong>
            {startIndex + 1}-{Math.min(endIndex, totalDataCount)}{" "}
          </strong>{" "}
          of <strong>{totalDataCount}</strong> results.
        </div>
        <div className="flex justify-between gap-4 pt-6 lg:py-0">
          <button
            className="flex justify-center items-center text-sm font-semibold border-2 rounded-md border-[#234777] hover:bg-[#234777] hover:text-white py-1 px-4"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <div>
              <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="text-xl " height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path className="hover:text-white" fill="currentColor" stroke="currentColor" stroke-width="2" d="M6,12.4 L18,12.4 M12.6,7 L18,12.4 L12.6,17.8" transform="matrix(-1 0 0 1 24 0)"></path></svg>
            </div>
            PREVIOUS
          </button>
          <button
            className="flex justify-center items-center text-sm font-semibold border-2 rounded-md border-[#234777] hover:bg-[#234777] hover:text-white py-1 px-4"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage * itemsPerPage >= totalDataCount}
          >
            NEXT
            <div>
              <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="text-xl" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path className="hover:text-white" fill="currentColor" stroke="currentColor" stroke-width="2" d="M6,12.4 L18,12.4 M12.6,7 L18,12.4 L12.6,17.8"></path></svg>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default FavoriteAds;
